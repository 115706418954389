@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
nav {
  top:0;
  position: sticky;
  z-index: 99;
  width: 100%;
  background: #f1f1f1;
}
nav .nav-wrapper {
  position: relative;
  max-width: 1980px;
  padding: 0px 30px;
  height: 70px;
  line-height: 70px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav-wrapper .logo a {
  width: 150px;
  height: 70px;
  display: flex;
}

.nav-wrapper .logo a img {
  display: flex-start;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.nav-wrapper .nav-links {
  display: inline-flex;
}
.nav-links li {
  list-style: none;
}
.nav-links li a {
  background: #f1f1f1;
  color: #000;
  text-decoration: none;
  font-size: 18px;
  font-weight:500;
  padding: 9px 15px;
  border-radius: 5px;
  transition: all 0.3s ease;
}
.nav-links li a:hover {
  color: #e85222;
}
.nav-links .mobile-item {
  display: none;
}
.nav-links .drop-menu {
  position: absolute;
  background: #f1f1f1;
  width: 200px;
  line-height: 45px;
  top: 85px;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}
.nav-links li:hover .drop-menu,
.nav-links li:hover .mega-box {
  cursor: pointer;
  transition: all 0.3s ease;
  top: 70px;
  opacity: 1;
  visibility: visible;
}
.drop-menu li a {
  width: 100%;
  display: block;
  padding: 0 0 0 15px;
  font-weight: 500;
  border-radius: 0px;
}
.mega-box {
  position: absolute;
  left: 0;
  width: 100%;
  padding: 0 30px;
  top: 85px;
  opacity: 0;
  visibility: hidden;
}

.mega-box .content {
  background: #f1f1f1;
  padding: 25px 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-radius: 0px 0px 20px 20px;
}
.mega-box .content .row {
  width: calc(25% - 30px);
  line-height: 45px;
}
.content .row img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}
.content .row header {
  color: #f1f1f1;
  font-size: 20px;
  font-weight: 500;
}
.content .row .mega-links {
  margin-left: -40px;
  border-left: 1px solid rgba(255, 255, 255, 0.09);
}
.row .mega-links li {
  padding: 0 20px;
}
.row .mega-links li a {
  padding: 0px;
  padding: 0 20px;
  color: #000;
  font-size: 17px;
  display: block;
}
.row .mega-links li a:hover {
  color: #e85222;
}
.nav-wrapper .btn {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  display: none;
}
.nav-wrapper .btn.close-btn {
  position: absolute;
  right: 30px;
  top: 10px;
}
.nav-links li a::after {
  content: "";
  width: 0%;
  height: 2px;
  margin: 0%;
  transition: 0.5s;
}

.nav-links li a:hover::after {
  width: 0%;
}
@media screen and (max-width: 970px) {
  .nav-wrapper .btn {
    color: #000;
    display: block;
  }
  .nav-wrapper .nav-links {
    position: fixed;
    height: 100vh;
    width: 100%;
    max-width: 350px;
    top: 0;
    left: -100%;
    background: #f1f1f1;
    display: block;
    padding: 50px 10px;
    line-height: 50px;
    overflow-y: auto;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.18);
    transition: all 0.3s ease;
  }
  /* custom scroll bar */
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #f1f1f1;
  }
  #menu-btn:checked ~ .nav-links {
    left: 0%;
  }
  #menu-btn:checked ~ .btn.menu-btn {
    display: none;
  }
  #close-btn:checked ~ .btn.menu-btn {
    overflow-y: auto;
    display: block;
  }
  .nav-links li {
    margin: 15px 10px;
  }
  .nav-links li a {
    padding: 0 20px;
    display: block;
    font-size: 20px;
  }
  .nav-links .drop-menu a{
    font-weight: 500;
  }
  .nav-links .drop-menu {
    position: static;
    opacity: 1;
    top: 65px;
    visibility: visible;
    padding-left: 20px;
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    box-shadow: none;
    transition: all 0.3s ease;
  }
  #showDrop:checked ~ .drop-menu,
  #showMega:checked ~ .mega-box {
    max-height: 100%;
  }
  .nav-links .desktop-item {
    display: none;
  }
  .nav-links .mobile-item {
    display: block;
    color: #000;
    font-size: 20px;
    font-weight: 500;
    padding-left: 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;
  }
  .nav-links .mobile-item:hover {
    color: #e85222;
  }
  .drop-menu li {
    margin: 0;
  }
  .drop-menu{
    border-radius: 0px 0px 20px 20px;
  }
  .drop-menu li a {
    border-radius: 5px;
    font-size: 15px;
  }
  label{
    margin:0;
  }
  .mega-box {
    position: static;
    top: 65px;
    opacity: 1;
    visibility: visible;
    padding: 0 20px;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.3s ease;
  }
  .mega-box .content {
    box-shadow: none;
    flex-direction: column;
    padding: 20px 20px 0 20px;
  }
  .mega-box .content .row {
    width: 100%;
    margin-bottom: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
  }
  .mega-box .content .row:nth-child(1),
  .mega-box .content .row:nth-child(2) {
    border-top: 0px;
  }
  .content .row .mega-links {
    border-left: 0px;
    padding-left: 15px;
  }
  .row .mega-links li {
    margin: 0;
  }
  .content .row header {
    font-size: 19px;
  }
}
nav input {
  display: none;
}

.body-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  padding: 0 30px;
}
.body-text div {
  font-size: 45px;
  font-weight: 600;
}
.desktop-item{
  cursor: pointer;
}