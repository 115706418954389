@import url('http://fonts.cdnfonts.com/css/cascadia-code');
@import url('https://fonts.googleapis.com/css?family=Spartan');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Cascadia Code', consolas, 'Courier New', monospace;
}

/* HEADINGS */
body {
  background-color: #f1f1f1;
}

.h2 {
  margin-left: 20px;
  text-align: left;
  color: black;
  font-size: 1.9rem;
}

span > p {
  padding: 15px 20px 15px 15px;
  text-align: left;
  color: black;
  width: 70%;
  font-family: 'Spartan';
  font-size: 18px;
}
.boutUsMainDiv{
  overflow: hidden;
  padding: 0 35px;
}
/*About Us*/
.about {
  width: 80%;
  margin: auto;
  text-align: center;
}

.about h1 {
  margin: 0%;
  text-align: center;
  color: black;
  font-family: 'Cascadia Code', consolas, 'Courier New', monospace;
  font-size: 1.6rem;
}

.principalmsg h1 {
  text-align: left;
  color: #121971;
  padding-left: 20px;
  font-weight: bold;
}

.mesg{
  width: 100%;
  height: 100%;
  display:grid;
  grid-template-columns: 50% 50%;
}
.principalmsg img{
  margin-left: 20%;
}

.msgdesc{
  width: 100%;
  height: 100%;
}

.principalmsg h2 {
  text-align: left;
  color: #121971;
  padding-left: 21px;
  font-weight: lighter;
  font-size: 25px;
  font-family: 'Spartan';
}

.abtrow {
  display: flex;
  justify-content: space-between;
  margin-top: 5%;
}

.aboutCol {
  flex-basis: 24%;
  background: #f1f1f1;
  border-radius: 10px;
  padding: 20px 12px;
  margin-bottom: 5%;
}

.aboutCol h3 {
  color: black;
  text-align: center;
  font-family: 'Cascadia Code', consolas, 'Courier New', monospace;
  margin: 10px 0;
}

.aboutCol p {
  margin: 0%;
  color: black;
}

.principalmsg p {
  text-align: left;
  font-family: 'Spartan';
  color: #121971;
  padding: 0px 20px 20px 20px;
  font-size: 18px;
}

.abtmission:hover {
  box-shadow: 0 0 20px 0 #121971;
}

.abtculture:hover {
  box-shadow: 0 0 20px 0 #E85222;
}

.abtvalues:hover {
  transform: scale(1.02);
  box-shadow: 0 0 20px 0 #121971;
}

.abtvision:hover {
  box-shadow: 0 0 20px 0 #E85222;
}

.hrforheading {
  height: 3px;
  border-radius: 20px;
  background: #121971;
  visibility: visible;
  margin: 0 0 0 0;
}

.abtcontainer {
  display: flex;
  width: 100%;
  height: 100%;
}

.ytvid>iframe {
  width: 450px;
  height: 300px;
}

.brochures {
  display: flex;
  justify-content: space-around;
}

.brochures figcaption {
  text-align: center;
  padding-top: 10px;
  font-family: 'Spartan';
}

.ytvid {
  margin: 0 auto;
  width: 30%;
  height: 100%;
}

@media (max-width:900px) {
  .principalmsg img{
    margin-left: 0%;
  }
  
  .boutUsMainDiv {
    padding: 0 15px;
  }

  .mesg{
    display: flex;
    flex-direction: column;
  }
  .principalimg{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .abtcontainer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .brochures {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }

  .brochures img {
    margin-top: 20px;
  }

  span>p {
    padding: 0px 20px 20px 20px;
  }

  .abtrow {
    flex-direction: column;
  }

  .ytvid {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  span>p {
    width: 100%;
  }

  .counters {
    display: flex;
    flex-direction: column;
  }

  .msgtxt h1{
    padding-left: 0px;
  }
  .msgtxt h2 {
    padding-left: 1.2px;
  }
}


.counters svg {
  vertical-align: inherit;
  margin-bottom: 8px;
}

.counters {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 15px;
}

.counters h4 {
  border-radius: 15px;
  background-color: #E85222;
  color: #f1f1f1;
  padding: 0.6rem;
}

.student {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.counters h5 {
  border: 2.8px solid #E85222;
  border-radius: 10px;
  padding: 0.3rem;
  color: #121971;
  font-size: 18.6px;
}

.faculties {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.since {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.typingl1 {
  width: 25ch;
  animation: typing 2s steps(22), .5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  font-family: monospace;
  font-size: 2em;
}


@keyframes typing {
  from {
    width: 0
  }
}

@keyframes blink {
  50% {
    border-color: transparent
  }
}

.counters h5:hover {
  transform: scale(1.02);
}