.swiper {
  width: 95%;
  height: 600px;
  border-radius: 15px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #f1f1f1;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper .swiper-pagination-bullet {
  width: 15px;
  height: 15px;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  color: #E85222;
}
.swiper-button-next:hover,
.swiper-button-prev:hover{
  transform: scale(1.02);
}
.swiper .swiper-pagination-bullet {
  width:10px;
  height:10px;
}
.swiper .swiper-pagination-bullet-active {
  background-color: #E85222;
}

@media (max-width:800px) {
  .swiper-slide img {
    object-fit: cover;
    border-radius: 20px;
  }

  .swiper {
    width: 95%;
    height: 315px;
  }
}