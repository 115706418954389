* {
  box-sizing: border-box;
}

body {
  margin: 0px;
}

.nav {
  z-index: 2;
  display: flex;
  justify-content: space-between;
  height: 50px;
  width: 100%;
  background-color: #f1f1f1;
  position: relative;
}

.nav>.nav-header {
  display: inline;
}

.nav>.nav-header>.nav-title {
  display: inline-block;
  width: 100px;
  height: 50px;
  border-radius: 50px;
  margin-left: 1rem;
}

.nav-title img {
  width: 140%;
  height: 100%;
  object-fit: contain;
}

.nav>.nav-btn {
  display: none;
}

.nav>.nav-links {
  display: flex;
  float: right;
  font-size: 18px;
}

.nav>.nav-links>a {
  padding: 5px 20px 13px 10px;
  text-decoration: none;
  color: black;
  font-size: 20px;
}

.nav>.nav-links>.a:hover {
  color: #E85222;
}

.nav>#nav-check {
  display: none;
}

hr {
  visibility: hidden;
}

.nav>.nav-links .a::after {
  content: "";
  width: 0%;
  background-color: #E85222;
  height: 2.8px;
  display: block;
  margin: auto;
  transition: 0.5s;
  border-radius: 4px;
}

.nav>.nav-links .a:hover::after {
  width: 100%;
}

@media (max-width:900px) {
  .nav>.nav-links .a::after {
    content: "";
    width: 0%;
    height: 0px;
    margin: 0%;
    transition: 0.5s;
    border-radius: 0px;
  }

  .nav>.nav-links .a:hover::after {
    width: 0%;
  }

  .nav>.nav-btn {
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 0px;
  }

  .nav>.nav-btn>label {
    display: inline-block;
    width: 50px;
    height: 50px;
    padding: 13px;
    border-radius: 10px;
  }

  .menu {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }

  .nav>.nav-btn>label>.menu>span:nth-child(1) {
    display: block;
    width: 15px;
    height: 10px;
    border-top: 2px solid black;
    transition: 0.3s ease;
  }

  .nav>.nav-btn>label>.menu>span:nth-child(2) {
    display: block;
    width: 25px;
    height: 10px;
    border-top: 2px solid black;
    transition: 0.3s ease;
  }

  .nav>.nav-btn>label>.menu>span:nth-child(3) {
    display: block;
    width: 15px;
    height: 10px;
    border-top: 2px solid black;
    transition: 0.3s ease;
  }



  .nav>.nav-links {
    position: absolute;
    display: block;
    width: 100%;
    background-color: #f1f1f1;
    height: 0px;
    transition: all 0.3s ease-in;
    overflow-y: hidden;
    top: 50px;
    left: 0px;
    color: black;

  }

  hr {
    visibility: visible;

  }

  .nav>.nav-links>.a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;
  }

  input[type="checkbox"]:checked~.nav-links {
    height: 0px;
  }

  input[type="checkbox"]:checked~.nav-links {
    height: calc(100vh - 50px);
    overflow-y: auto;
  }

  input[type="checkbox"]:checked~.nav-title>img {
    transform: rotate(45deg);
  }

  input[type="checkbox"]:checked~.nav-btn>.label>.menu>.span1 {
    transform: rotate(45deg) translate(-3px, 3px);
    transform-origin: left;
  }

  input[type="checkbox"]:checked~.nav-btn>.label>.menu>.span2 {
    transform: rotate(-45deg) translate(6px, 5px);
    transform-origin: center;
  }

  input[type="checkbox"]:checked~.nav-btn>.label>.menu>.span3 {
    transform: rotate(45deg) translate(-1px, -1px);
    transform-origin: right;
  }
}

.nav>.nav-links hr {
  visibility: hidden;
}