.main404 {
  height: 60vh;
  background: no-repeat center fixed;
  background-size: cover;
  /* version standardisée */
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: source-sans-pro, sans-serif;
  background-color: #f1f1f1;
  /* #ededed*/
  color: #333;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.main404 h2 {
  display: inline;
  font-size: 150px;
  margin: 0;
  padding: 0;
  line-height: 0;
  margin-top: 50px;
}


.main404 .quote {
  display: inline;
  margin-left: 5px;
  margin-right: 5px;
}

.main404 .quote:before {
  content: '{';

}

.main404 .quote:after {
  content: '}';

}

.main404 .error {
  text-align: center;
  position: relative;
  margin-top: 70px;

}

.main404 .text {
  position: absolute;
  right: 13px;
}

.main404 .container {
  width: 334px;
  padding: 5px;
}

.main404 .info-label {
  display: inline;
}

.main404 .info-text {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  flex-direction: column;
  text-align: center;
}


/* .main404 a:after {
    vertical-align: top;
    width: 13px;
    height: 13px;
    background-size: 100%;
    background-repeat: no-repeat;
    content: '';
    display: inline-block;

} */

.main404 p {
  text-align: justify;
}

.info-text .backbtn {
  position: relative;
  flex: 1;
  color: rgba(255, 255, 255, 0.6);
  text-decoration: none;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  margin: 0 20px;
  text-align: center;
  padding: 10px 10px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  z-index: 3;
}

@media (max-width: 300px) {
  .info-text .backbtn {
    flex: 5 100%;
    width: 100%;
  }
}

.info-text .backbtn:hover {
  border: 1px solid #ffffff;
  color: #121212;
}

.info-text .backbtn:hover span {
  width: 100px;
  height: 100px;
}

.info-text .backbtn span {

  content: '';
  position: absolute;
  background: #e85222;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-radius: 50%;
  transition: all 0.5s ease;
  z-index: -1;
}

@media (max-width: 300px) {
  .info-text .backbtn span {
    transition: all 0.2s ease-in;
  }
}

.info-text .backbtn span:active {

  background: #e85222;
}

.info-text .backbtn span:hover {
  overflow: hidden;
}