@import url("https://fonts.googleapis.com/css?family=Arvo");

body,
html {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  background: #f1f1f1;
  font-size: 13px;
  font-family: "Arvo", monospace;
}

@supports (display: grid) {

  body,
  html {
    display: block;
  }
}

.message {
  border: 1px solid #d2d0d0;
  padding: 2em;
  font-size: 1.7vw;
  box-shadow: -2px 2px 10px 0px rgba(68, 68, 68, 0.4);
}

@supports (display: grid) {
  .message {
    display: none;
  }
}

.section {
  display: none;
  padding: 0 2rem;
}

@media screen and (min-width: 768px) {
  .section {
    padding: 0 04rem;
  }
}

@supports (display: grid) {
  .section {
    display: block;
  }
}

.section h1 {
  color: black;
  font-size: 2rem;
  margin: 0 0 1.5em;
}

.grid {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: 150px;
  grid-auto-flow: row dense;
}

.item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-sizing: border-box;
  background: #0c9a9a;
  color: #fff;
  grid-column-start: auto;
  grid-row-start: auto;
  color: #fff;
  background-size: cover;
  background-position: center;
  box-shadow: -2px 2px 10px 0px rgba(68, 68, 68, 0.4);
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  border-radius: 20px;
  counter-increment: item-counter;
}

.item:nth-of-type(3n) img {
  object-fit: cover;
  width: 100%;
}

.item:nth-of-type(1n) {
  background-image: url("https://excellentclasses.in/wp-content/uploads/2021/11/6.jpg");
}

.item:nth-of-type(2n) {
  background-image: url("https://excellentclasses.in/wp-content/uploads/2021/11/11.jpg");
}

.item:nth-of-type(3n) {
  background-image: url("https://excellentclasses.in/wp-content/uploads/2022/01/DSC_7414-scaled.jpg");
}

.item:nth-of-type(4n) {
  background-image: url("https://excellentclasses.in/wp-content/uploads/2022/11/WhatsApp-Image-2022-11-24-at-2.31.44-PM-3.jpeg");
}

.item:nth-of-type(5n) {
  background-image: url("https://res.cloudinary.com/dp9icjdvf/image/upload/v1662401558/Excellent%20Classes%20/10582918_736431616403881_7476763419006628785_o_phv2ec.jpg");
}

.item:nth-of-type(6n) {
  background-image: url("https://excellentclasses.in/wp-content/uploads/2022/11/WhatsApp-Image-2022-11-24-at-2.31.45-PM-1.jpeg ");
}

.item:nth-of-type(7n) {
  background-image: url("https://excellentclasses.in/wp-content/uploads/2022/11/WhatsApp-Image-2022-11-24-at-2.31.47-PM-1-1.jpeg");
}

.item:nth-of-type(8n) {
  background-image: url("https://excellentclasses.in/wp-content/uploads/2022/11/WhatsApp-Image-2022-11-24-at-2.31.47-PM-2.jpeg");
}

.item:nth-of-type(9n) {
  background-image: url("https://excellentclasses.in/wp-content/uploads/2021/11/12.jpg");
}

.item:nth-of-type(10n) {
  background-image: url("https://excellentclasses.in/wp-content/uploads/2022/01/DSC_7656-scaled.jpg");
}

.item:nth-of-type(11n) {
  background-image: url("https://res.cloudinary.com/dp9icjdvf/image/upload/v1665664307/Excellent%20Classes%20/WhatsAppImage2022-01-19at70221PM-830x467_poug4h.jpg");
}

.item:nth-of-type(12n) {
  background-image: url("https://res.cloudinary.com/dp9icjdvf/image/upload/v1665671023/Excellent%20Classes%20/banner345-2048x1039_ckmuzl.png");
}


.item:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  border-radius: 20px;
  opacity: 0.3;
  transition: opacity 0.3s ease-in-out;
}

.item:hover {
  transform: scale(1.05);
}

.item:hover:after {
  opacity: 0;
}

.item--medium {
  grid-row-end: span 2;
}

.item--large {
  grid-row-end: span 3;
}

.item--full {
  grid-column-end: span 10;
  object-fit: contain;
}

@media screen and (max-width: 1600px) {
  .item--full {
    grid-column: 1/-1;
    grid-row-end: span 4;

  }
}

.item__details {
  position: relative;
  z-index: 1;
  padding: 15px;
  color: #444;
  background: #fff;
  text-transform: lowercase;
  letter-spacing: 1px;
  color: #828282;
}

.item__details:before {
  content: counter(item-counter);
  font-weight: bold;
  font-size: 1.1rem;
  padding-right: 0.5em;
  color: #444;
}