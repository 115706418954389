@import url('http://fonts.cdnfonts.com/css/cascadia-code');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Cascadia Code', consolas, 'Courier New', monospace;
}

/* HEADINGS */
body {
  background-color: #f1f1f1;
}

h1 {
  color: #fff;
  text-align: center;
  line-height: 1.4;

}

h1 {
  font-size: 2.2rem;
}

.feature-container h3 {
  color: #000;
  font-size: 1.8rem;
  text-align: center;
  line-height: 1.4;
  margin-bottom: 10px;
}

.page-wrapper {
  width: 100%;
  height: auto;
}


/* SECTIONS */
.homemain .h2 {
  text-align: center;
  margin-top: 35px;
  font-size: 35px;
  /* text-transform: uppercase; */
  line-height: 1.1em;
  color: #20314B;
}

.features {
  overflow: hidden;
  width: 100%;
  height: auto;
  background-color: #f1f1f1;
  display: flex;
  padding: 14px 20px;
  justify-content: space-around;
}

.feature-container {
  flex-basis: 30%;
  margin-top: 10px;
}

.feature-container i {
  width: 22px;
  height: 20px;
}

.feature-container p {
  color: #000;
  text-align: center;
  line-height: 1.4;
  margin-bottom: 15px;
}

.feature-container img {
  width: 100%;
  margin-bottom: 15px;
  border-radius: 15px;
}

/* MOBILE MENU & ANIMATION */
.no-search {
  transform: translate(0);
  transition: transform 0.7s ease-in-out;
}

.menu-toggle .bar {
  width: 25px;
  height: 3px;
  background-color: #3f3f3f;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.menu-toggle {
  justify-self: end;
  margin-right: 25px;
  display: none;
}

.menu-toggle:hover {
  cursor: pointer;
}

#mobile-menu.is-active .bar:nth-child(2) {
  opacity: 0;
}

#mobile-menu.is-active .bar:nth-child(1) {
  -webkit-transform: translateY(8px) rotate(45deg);
  -ms-transform: translateY(8px) rotate(45deg);
  -o-transform: translateY(8px) rotate(45deg);
  transform: translateY(8px) rotate(45deg);
}

#mobile-menu.is-active .bar:nth-child(3) {
  -webkit-transform: translateY(-8px) rotate(-45deg);
  -ms-transform: translateY(-8px) rotate(-45deg);
  -o-transform: translateY(-8px) rotate(-45deg);
  transform: translateY(-8px) rotate(-45deg);
}

/* KEYFRAME ANIMATIONS */

@-webkit-keyframes gradbar {
  0% {
    background-position: 0% 50%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50%
  }
}

@-moz-keyframes gradbar {
  0% {
    background-position: 0% 50%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50%
  }
}

@keyframes gradbar {
  0% {
    background-position: 0% 50%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50%
  }
}

/* Media Queries */

/* Mobile Devices - Phones/Tablets */

@media only screen and (max-width: 720px) {
  .features {
    flex-direction: column;
    /* padding: 50px; */
  }

  /* MOBILE HEADINGS */

  .features h1 {
    font-size: 1.9rem;
  }

  /* MOBILE NAVIGATION */
  .imagesfit {
    object-fit: contain;
  }


  .menu-toggle,
  .bar {
    display: block;
    cursor: pointer;
  }


  /* SECTIONS */

  .headline {
    height: 20vh;
  }

  .feature-container p {
    margin-bottom: 25px;
  }

  .feature-container {
    margin-top: 14px;
  }

  .feature-container:nth-child(2) {
    order: -1;
  }

}

/*##### PORTFOLIO SECTION #####*/
.project-description p {
  color: #000;
  font-size: 15px;
  text-align: center;
}

.portfolio {
  background: #f1f1f1;
  padding: 17px 0;
}

.portfolio .content {
  text-align: center;
}

.portfolio .content h1 {
  color: #3c3c3c;
  font-size: 2em;
  margin-bottom: 50px;
}

.portfolio .content .projects {
  width: 100%;
}

.portfolio .content .projects .project {
  color: #fff;
  position: relative;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 10%;
  overflow: hidden;
}

.portfolio .content .projects .project:last-child {
  margin-bottom: 0;
}

.portfolio .content .projects .project .project-image img {
  border-radius: 15px;
  width: 100%;
}

.portfolio .content .projects .project .project-title {
  color: #3c3c3c;
  padding: 10px 0;
}

.portfolio .content .projects .project .project-description {
  color: rgb(0, 0, 0);
}


/*Desktop View*/
@media screen and (min-width: 960px) {
  .portfolio .content .projects .project {
    position: relative;
    margin: auto;
    margin-bottom: 3%;
    width: 680px;
    height: 450px;
    overflow: hidden;
  }

  .portfolio .content .projects .project:hover .project-image {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  .portfolio .content .projects .project:hover .project-title,
  .portfolio .content .projects .project:hover .project-description {
    opacity: 1;
  }

  .portfolio .content .projects .project .project-image {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: transform 0.5s ease;
    transition: transform 0.5s ease;
    -webkit-transform-origin: left top;
    transform-origin: left top;
    z-index: 3;
    overflow: hidden;
  }

  .portfolio .content .projects .project .project-image img {
    width: 100%;
    height: auto;
    min-height: 100%;
    min-width: 100%;
  }

  .portfolio .content .projects .project .project-title,
  .portfolio .content .projects .project .project-description {
    position: absolute;
    height: 50%;
    opacity: 0;
    -webkit-transition: opacity 0.3s linear;
    transition: opacity 0.3s linear;
    overflow: hidden;
  }

  .portfolio .content .projects .project .project-title {
    width: 50%;
    top: 0;
    right: 0;
    padding: 10px;
    text-align: center;
    display: table;
  }

  .portfolio .content .projects .project .project-title h2 {
    font-size: 2em;
    display: table-cell;
    vertical-align: middle;
  }

  .portfolio .content .projects .project .project-description {
    width: 100%;
    top: 50%;
    left: 0;
    padding: 25px 10px;
    text-align: left;
  }
}

.footer-widget ul li {
  display: inline-block;
  width: 50%;
}

/*Marquee*/
@-webkit-keyframes marquee {
  0% {
    margin-left: 100%;
    transform: translateX(0%);
  }

  100% {
    margin-left: 0;
    transform: translateX(-100%);
  }
}

@keyframes marquee {
  0% {
    margin-left: 100%;
    transform: translateX(0%);
  }

  100% {
    margin-left: 0;
    transform: translateX(-100%);
  }
}

.sectionm p {
  font-size: 17px;
}

.sectionm {
  overflow: hidden;
  background-color: #f1f1f1;
}

.sectionm .marquee {
  -webkit-animation: marquee 40s linear infinite;
  animation: marquee 40s linear infinite;
  display: inline-block;
  white-space: nowrap;
}

.sectionm .marquee:hover {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.text-styling {
  color: #E85222;
  padding: 1em;
}

/*Go To top Icon*/
.gototop {
  position: fixed;
  bottom: 2%;
  right: 2%;
  text-align: center;
  width: 30px;
  height: 25px;
  background-color: white;
}

.gototop:hover {
  background-color: #E85222;
}

.gototop a:hover {
  text-decoration: none;
}



/*main div btn*/
/* CSS */
#btndiv {
  display: flex;
  justify-content: center;
}

.btnen {
  font-weight: 600;
  color: #f1f1f1;
  background-color: #000;
  border-radius: 20px;
  width: 130px;
  height: 50px;
  border: 3.1px solid #E85222;
}

.btnen:hover {
  transform: scale(1.02);
  background-color: #E85222;
  transition: all 400ms ease-out;
}
.testimonials{
  display: flex;
  justify-content: space-evenly;
}
.testimonials figure{
  margin: 20px 40px 20px;
}
.linktotestimonials{
  text-decoration: none;
  display: flex;
  flex-direction: row-reverse;
  padding-right: 30px;
}
.linktotestimonials a{
  color: #E85222  ;
}
.linktotestimonials a:hover{
  text-decoration: underline #E85222;
}
.testimonials figcaption, .testimonials figure p{
  text-align:left;
  color: #000;
  font-family: 'Spartan';
  margin-bottom: 0;
}
@media (max-width:900px) {
  .testimonials{
    display: flex;
    justify-content:center;
    flex-direction: column;
  }
  .testimonials figure{
    margin: 8px 0px 5px 8px;
  }
  .testimonials figcaption{
    text-align: left;
  }
}