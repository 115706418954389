
ul {
  margin: 0px;
  padding: 0px;
}

.footer-section {
  background: #f1f1f1;
  position: relative;
}

/* .footer-cta {
    border-bottom: 1px solid #373636;
  } */
.single-cta i {
  color: #E85222;
  font-size: 30px;
  float: left;
}
.single-cta i, .single-cta h4, .single-cta span  {
  cursor: pointer;
}

.cta-text {
  padding-left: 15px;
  display: inline-block;
}

.cta-text h4 {
  color: #E85222;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}

.cta-text span {
  color: #757575;
  font-size: 15px;
}

.footer-content {
  position: relative;
  z-index: 2;
}

.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}

.footer-logo {
  margin-bottom: 30px;
}

.footer-logo img {
  cursor: pointer;
  object-fit: contain;
}

.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #7e7e7e;
  text-align: center;
  line-height: 28px;
}

.footer-social-icon {
  display: flex;
  justify-content: center;
}

.footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 20px;
}

.footer-social-icon a {
  color: #fff;
  font-size: 16px;
  margin-right: 15px;
}

.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
  margin-bottom: 30px;
}

.facebook-bg {
  background: #3B5998;
}

.instagram-bg {
  background: #55ACEE;
}

.whatsapp-bg {
  background: #4DCB5B;
}

.justdial-bg {
  background: #FF6C00;
}

.youtube-bg {
  background: #FF0000;
}

.footer-widget-heading h3 {
  color: #E85222;
  font-size: 20px;
  font-weight: 600;
  position: relative;
}

/* .footer-widget-heading h3::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -15px;
    height: 4px;
    width: 100px;
    border-radius: 20px;
    background: #E85222;
  } */
.linedivide {
  height: 3px;
  border-radius: 20px;
  background: #E85222;
  visibility: visible;
  margin: 0.5rem 0 0.5rem 0;
}

.footer-widget ul {
  display: flex;
  flex-direction: column;
}

.footer-widget ul li {
  margin-bottom: 12px;
}

.footer-widget ul li a:hover {
  color: #E85222;
}

.footer-widget ul li a {
  color: #878787;
  text-transform: capitalize;
}

.subscribe-form {
  position: relative;
  overflow: hidden;
}

.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #2E2E2E;
  border: 1px solid #2E2E2E;
  color: #fff;
}

.subscribe-form button {
  position: absolute;
  right: 0;
  background: #E85222;
  padding: 13px 20px;
  border: 1px solid #E85222;
  top: 0;
}

.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}

.copyright-area {
  justify-content: center;
  background: #202020;
  padding: 25px 0;
}

.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #878787;
}

.copyright-text p a {
  color: #E85222;
}

.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}

.footer-menu li:hover {
  color: #E85222;
}

.footer-menu li a {
  font-size: 14px;
  color: #000000;
}

@media (max-width:900px) {
  .footer-widget ul {
    display: inline-block;
  }
}

.svg-up {
  top: 0;
}

.footer-social-icon i:hover {
  transform: scale(1.1);
  transition: all ease-out;
}